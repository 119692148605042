.addlist h3 {
  margin-left: 20px;
  display: flex;
  border-left: 4px solid #4574f9;
  margin-bottom: 30px;
}
.addlist h3 span {
  margin-left: 20px;
}
.addlist h4 {
  margin-left: 20px;
  display: flex;
  margin-bottom: 30px;
}
.addlist h4 span {
  margin-left: 20px;
}
.form-box .form-bg {
  background-color: #f7f8fd;
}
.form-box .input_w {
  width: 30%;
}
.form-box .input_w_80 {
  width: 80%;
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
}
.btn-box button {
  padding: 12px 30px;
}
