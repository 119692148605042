
































































































































































































































































































.addlist {
  h3 {
    margin-left: 20px;
    display: flex;
    border-left: 4px solid #4574f9;

    span {
      margin-left: 20px;
    }

    margin-bottom: 30px;
  }
  h4 {
    margin-left: 20px;
    display: flex;

    span {
      margin-left: 20px;
    }

    margin-bottom: 30px;
  }
}

.form-box {
  // padding: 10px 20px;
  .form {
    // width: 80%;
    // padding: 20px;
  }

  .form-bg {
    background-color: rgb(247, 248, 253);
  }

  .input_w {
    width: 30%;
  }
  .input_w_80 {
    width: 80%;
  }
}

.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  // width: 80%;
  button {
    padding: 12px 30px;
  }
}
